
import { defineComponent, ref, reactive, onMounted } from 'vue';
import Multiselect from '@vueform/multiselect';
import router from '@/router';
import Calendar from 'primevue/calendar';
import useOrder from '@/modules/useOrder';
import { DocumentsFilter } from '@/models/Document';
import { DocumentType } from '@/models/Enums';
import { generalStore } from '@/store';
import { DateRange, IdName } from '@/models/Interfaces';
import useProduct from '@/modules/useProduct';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import q from 'q';
import { Currency } from '@/models/Interfaces';

export default defineComponent({
    components: {
        Multiselect,
        Calendar
    },
    name: 'OrderFilter',
    data() {
        return {};
    },
    async setup() {
        const calendar = ref();
        const loaded = ref(false);
        const filter = ref<DocumentsFilter>(new DocumentsFilter());
        const { dateRangeValidate } = useProduct();

        const documentType = ref<IdName[]>([]);
        const currenciesType = ref<IdName[]>([]);

        const { convertDate } = useOrder();
        const dateRange = reactive(new DateRange());
        if (filter.value?.createDateStart) {
            dateRange.fromDate = new Date(filter.value?.createDateStart);
        }
        if (filter.value?.createDateEnd) {
            dateRange.toDate = new Date(filter.value?.createDateEnd);
        }

        function ResetFilter() {
            generalStore.commit('saveDocumentsFilter', new DocumentsFilter());
            router.push({ name: 'documents' });
        }

        function Close() {
            router.push({ name: 'documents' });
        }

        function Search() {
            if (filter.value) {
                if (dateRange.fromDate) {
                    filter.value.createDateStart = convertDate(dateRange.fromDate);
                }
                if (dateRange.toDate) {
                    filter.value.createDateEnd = convertDate(dateRange.toDate);
                }
                filter.value.keyword = '';
            }

            generalStore.commit('saveDocumentsFilter', filter.value);
            router.push({ name: 'documents' });
        }

        const dateChanged = () => {
            if (!dateRange.fromDate || !dateRange.toDate) {
                return;
            }
            dateRangeValidate(dateRange);
        };
        async function getProviderDocTypes() {
            const apiResult = await api.GetProviderDocTypes();
            await q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data) {
                apiResult.data.forEach(docType => {
                    documentType.value.push({ id: docType.id, name: docType.name });
                });
            }
        }

        const onMountedCall = async () => {
            const filterCopy = generalStore.getters.getDocumentsFilter;
            filter.value = JSON.parse(JSON.stringify(filterCopy));

            for (const [propertyKey, propertyValue] of Object.entries(DocumentType)) {
                if (!Number.isNaN(Number(propertyKey))) {
                    continue;
                }
                if (propertyValue == DocumentType.Custom) {
                    continue;
                }
                documentType.value.push({ id: propertyValue.toString(), name: propertyKey });
            }

            await getProviderDocTypes();
            loaded.value = true;

            const currencies: Currency[] = generalStore.getters.currencies;

            currencies.forEach(currency => {
                currenciesType.value.push({id: currency.symbol, name: currency.symbol});
            });
        };

        onMounted(onMountedCall);

        return {
            filter,
            documentType,
            dateRange,
            ResetFilter,
            Close,
            Search,
            calendar,
            dateChanged,
            loaded,
            currenciesType
        };
    }
});
